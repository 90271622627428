<template>
  <v-container style="max-width: 600px" class="py-10">
    <please-wait class="mt-3" v-if="isLoading"></please-wait>
    <!-- <post-card :post="post" :open-output="true" v-else></post-card> -->
    <large-post-card :post="post" v-else></large-post-card>
  </v-container>
</template>
<script>
import PleaseWait from "@/components/ui/PleaseWait.vue";
import PostCard from "@/components/post/PostCard.vue";
import LargePostCard from "@/components/post/LargePostCard";
import { doc, getDoc } from "firebase/firestore";
import { colUsers } from "@/utils/firebase.utils";
export default {
  components: { PleaseWait, PostCard,LargePostCard },
  data() {
    return {
      post: {},
      isLoading: true,
      isMounted: false, // Flag to track if the component is still mounted
    };
  },
  methods: {
    async fetchPost() {
      const vm = this;
      try {
        vm.isLoading = true;

        // Validate route parameters
        let { uid, id, wid } = this.$route.params;
        if (!uid || !id) throw new Error("Invalid route parameters");

        if (!wid) wid = vm.WID;

        // Construct Firestore reference
        let postRef = doc(colUsers, uid, "fly_workspaces", wid, "social-posts", id);

        // Fetch document
        let post = (await getDoc(postRef)).data();
        if (post == null) {
          if (vm.isMounted) {
            vm.$router.replace("/dashboard");
            vm.$alert.show("Post not found");
          }
          return;
        }

        // Update post data if the component is still mounted
        if (vm.isMounted) {
          post.id = id;
          post.wid = wid;
          vm.post = post;
          vm.isLoading = false;
        }
      } catch (error) {
        if (this.isMounted) {
          vm.handleError(error);
        }
      }
    },
  },
  mounted() {
    this.isMounted = true; // Mark the component as mounted
    this.fetchPost();
    // setTimeout(() => {
    // }, 2000);
  },
  beforeDestroy() {
    this.isMounted = false; // Mark the component as unmounted
  },
};
</script>
