<template>
  <div>
    <div class="p-4 pb-0 flex">
      <p class="mt-2 text-lg font-semibold text-[#121212]">Aggregated Stats</p>
    </div>
    <div class="bg-white p-4 rounded-lg shadow-md mt-4 w-[98%] mx-2">
      <div class="overflow-x-auto">
        <div class="min-w-[1000px]">
          <div class="flex justify-between">
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Maps
                <tool-tip
                  :message="getTooltipMessage('maps', 'combined')"
                  class="tooltip-container"
                >
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading && metricsData?.length > 0">{{
                  getTotalMetric("maps") ? getTotalMetric("maps") : "0"
                }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
            <div class="border-l border-gray-300 mx-4"></div>
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Search
                <tool-tip :message="getTooltipMessage('search', 'combined')">
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading && metricsData?.length > 0">{{
                  getTotalMetric("search") ? getTotalMetric("search") : "0"
                }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
            <div class="border-l border-gray-300 mx-4"></div>
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Direction Requests
                <tool-tip
                  :message="getTooltipMessage('directions', 'combined')"
                >
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading && metricsData?.length > 0">{{
                  getTotalMetric("directions")
                    ? getTotalMetric("directions")
                    : "0"
                }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
            <div class="border-l border-gray-300 mx-4"></div>
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Call Clicks
                <tool-tip :message="getTooltipMessage('calls', 'combined')">
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading && metricsData?.length > 0">{{
                  getTotalMetric("calls") ? getTotalMetric("calls") : "0"
                }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
            <div class="border-l border-gray-300 mx-4"></div>
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Website Clicks
                <tool-tip :message="getTooltipMessage('website', 'combined')">
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading && metricsData?.length > 0">{{
                  getTotalMetric("website") ? getTotalMetric("website") : "0"
                }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
            <div class="border-l border-gray-300 mx-4"></div>
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Bookings
                <tool-tip :message="getTooltipMessage('bookings', 'combined')">
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading && metricsData?.length > 0">{{
                  getTotalMetric("bookings") ? getTotalMetric("bookings") : "0"
                }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
            <div class="border-l border-gray-300 mx-4"></div>
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Food Orders
                <tool-tip
                  :message="getTooltipMessage('food_orders', 'combined')"
                >
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading && metricsData?.length > 0">{{
                  getTotalMetric("food_orders")
                    ? getTotalMetric("food_orders")
                    : "0"
                }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
            <div class="border-l border-gray-300 mx-4"></div>
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Food Menu Clicks
                <tool-tip :message="getTooltipMessage('food_menu', 'combined')">
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading && metricsData?.length > 0">{{
                  getTotalMetric("food_menu")
                    ? getTotalMetric("food_menu")
                    : "0"
                }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="w-full items-center mx-auto px-4 my-9">
      <div
        class="relative flex justify-between flex-col md:flex-row items-start gap-6 md:items-center md:gap-2 grow"
      >
        <div class="gap-2 w-full md:w-1/3">
          <div class="relative">
            <input
              type="text"
              v-model="locationSearch"
              placeholder="Locations"
              @click="toggleStoreDropdown($event)"
              class="w-full pt-2 pr-8 border border-gray-300 rounded focus:outline-none pl-1 focus:ring-1 focus:ring-blue-500 placeholder-gray-500 font-medium text-base md:text-lg placeholder:text-base md:placeholder:text-lg"
            />
            <button
              @click="toggleStoreDropdown($event)"
              class="absolute inset-y-0 right-0 flex items-center px-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            <div
              ref="showStoreDropdown"
              v-if="showStoreDropdown"
              class="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-50 max-h-60 max-w-[800px] overflow-y-auto px-5"
            >
              <div
                v-for="profile in filteredStores"
                :key="profile.wid"
                class="platform p-2"
              >
                <div class="flex items-center gap-2">
                  <input
                    type="checkbox"
                    v-model="profile.checked"
                    @change="fetchStorePosts(profile)"
                  />
                  <div class="flex flex-col">
                    <span
                      class="font-medium text-base"
                      v-if="profile.sub_locality"
                      >{{ profile.sub_locality }}</span
                    >
                    <span class="text-sm">{{ profile.city }}</span>
                    <span class="text-sm">{{ profile.state }}</span>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-select
          v-model="selectedDate"
          :items="dateOptions"
          label="Select Month"
          dense
          outlined
          :loading="isLoadingDates"
          class="min-w-[200px] flex-1 sm:flex-none date-select"
          hide-details
          @change="selectDateRange"
        ></v-select>
      </div>
    </div>
    <!-- Tabs -->
    <div class="px-4">
      <div class="flex gap-4 text-sm md:text-base max-sm:justify-between">
        <button
          @click="handleTabChange('performance')"
          :class="[
            'tabs py-1 px-4 font-medium border hover:border-[#8250e8] hover:bg-[#7b68b6] hover:text-white transition-colors duration-200 rounded-full',
            activeTab === 'performance'
              ? 'bg-[#8056DE] text-white'
              : 'bg-white text-black',
          ]"
        >
          Store Performance
        </button>
        <button
          @click="handleTabChange('metrics')"
          :class="[
            'tabs py-1 px-4 font-medium hover:border-[#8250e8] rounded-full transition-colors duration-200 hover:bg-[#7b68b6] hover:text-white',
            activeTab === 'metrics'
              ? 'bg-[#8056DE] text-white'
              : 'bg-white text-black',
          ]"
        >
          Store Metrics
        </button>
      </div>
    </div>
    <!--  -->
    <div
      v-if="activeTab === 'performance'"
      class="flex flex-col items-center justify-center w-full pb-20"
    >
      <section
        class="flex flex-col items-center w-full justify-center xl:w-[100%]"
      >
        <div
          class="mt-[18px] w-full xl:w-[100%] flex justify-center items-center gap-4"
        >
          <div class="w-full bg-white">
            <div class="flex flex-col justify-between w-full px-5 py-2 mx-auto">
              <!-- <p class="mt-2 text-lg font-semibold text-[#121212]">
                Store Performance
              </p> -->

              <div
                class="p-4 bg-white shadow-lg rounded-lg max-w-[1900px] my-4"
              >
                <div class="overflow-x-auto w-full">
                  <div v-if="isLoading">
                    <v-skeleton-loader
                      type="table"
                      class="mb-4"
                      boilerplate
                    ></v-skeleton-loader>
                  </div>
                  <div
                    v-else-if="!metricsData || metricsData?.length === 0"
                    class="flex justify-center items-center py-8"
                  >
                    <p class="text-gray-500">No data available</p>
                  </div>
                  <div v-else class="">
                    <div
                      class="bg-gray-100 min-w-[1000px] rounded-t-lg text-xs w-full"
                    >
                      <div
                        class="flex items-center justify-between text-gray-600 p-2 flex-wrap"
                      >
                        <div
                          class="w-1/6 text-start whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                          @click="sortData('location')"
                        >
                          Location
                          <v-icon small>
                            {{
                              sortBy === "location"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                          @click="sortData('desktop_maps_impressions')"
                        >
                          Desktop<br />
                          Maps
                          <!-- <v-icon small class="pr-1">mdi-web</v-icon> -->
                          <tool-tip
                            :message="getTooltipMessage('maps', 'desktop')"
                          >
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "desktop_maps_impressions"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                          @click="sortData('mobile_maps_impressions')"
                        >
                          Mobile<br />
                          Maps

                          <!-- Maps <v-icon small>mdi-cellphone</v-icon> -->
                          <tool-tip
                            :message="getTooltipMessage('maps', 'mobile')"
                          >
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "mobile_maps_impressions"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-2"
                          @click="sortData('desktop_search_impressions')"
                        >
                          Desktop<br />
                          Search
                          <!-- Search <v-icon small class="pr-1">mdi-web</v-icon> -->
                          <tool-tip
                            :message="getTooltipMessage('search', 'desktop')"
                          >
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "desktop_search_impressions"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-2"
                          @click="sortData('mobile_search_impressions')"
                        >
                          Mobile<br />
                          Search
                          <!-- Search
                          <v-icon small class="pr-1">mdi-cellphone</v-icon> -->
                          <tool-tip
                            :message="getTooltipMessage('search', 'mobile')"
                          >
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "mobile_search_impressions"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                          @click="sortData('direction_requests')"
                        >
                          Direction<br class="d-xl-none" />
                          Requests
                          <tool-tip :message="getTooltipMessage('directions')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "direction_requests"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-5 cursor-pointer hover:bg-gray-200"
                          @click="sortData('call_clicks')"
                        >
                          Call<br />
                          Clicks
                          <tool-tip :message="getTooltipMessage('calls')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "call_clicks"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                          @click="sortData('website_clicks')"
                        >
                          Website <br />
                          Clicks
                          <tool-tip :message="getTooltipMessage('website')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "website_clicks"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap pr-6 cursor-pointer hover:bg-gray-200"
                          @click="sortData('bookings')"
                        >
                          Bookings
                          <tool-tip :message="getTooltipMessage('bookings')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "bookings"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                          @click="sortData('food_orders')"
                        >
                          Food <br />Orders
                          <tool-tip :message="getTooltipMessage('food_orders')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "food_orders"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-4 cursor-pointer hover:bg-gray-200"
                          @click="sortData('food_menu_clicks')"
                        >
                          Food Menu <br />Clicks
                          <tool-tip :message="getTooltipMessage('food_menu')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "food_menu_clicks"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                      </div>
                    </div>

                    <div class="min-w-[1000px]">
                      <div
                        v-for="metric in metricsData"
                        :key="metric.location.wid"
                        class="border-b border-gray-200"
                      >
                        <div
                          v-if="
                            metric.location?.name ||
                            metric.location?.storeLocation
                          "
                          class="flex justify-between items-center w-full text-xs bg-white hover:bg-gray-100 py-2"
                        >
                          <div class="w-1/6 px-2">
                            <div class="flex flex-col items-start">
                              <span
                                v-if="metric.location?.sub_locality"
                                class="font-semibold"
                                >{{ metric.location.sub_locality }}</span
                              >
                              <span
                                v-else-if="
                                  !metric.location?.sub_locality &&
                                  metric.location?.storeLocation
                                "
                                class="text-[12px]"
                                >{{ metric.location.storeLocation }}</span
                              >
                              <div class="flex gap-1 flex-col items-start">
                                <div
                                  v-if="metric.location?.city"
                                  class="text-xs"
                                >
                                  {{ metric.location.city }}
                                </div>
                                <span
                                  v-if="metric.location?.state"
                                  class="text-xs"
                                  >{{ metric.location?.state }}</span
                                >
                              </div>
                            </div>
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.desktop_maps_impressions }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.mobile_maps_impressions }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.desktop_search_impressions }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.mobile_search_impressions }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.direction_requests }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.call_clicks }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.website_clicks }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.bookings }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.food_orders }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.food_menu_clicks }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="flex justify-center mt-6">
        <div class="flex items-center gap-4">
          <button
           @click="prevPage"
           :disabled="currentPage === 1"
            class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border disabled:cursor-not-allowed border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
          >
            Previous
          </button>
          <div class="flex items-center gap-2">
            <span class="text-sm text-gray-700">Page</span>
            <span class="font-medium">{{ totalPages ? currentPage : 0 }}</span>
            <span class="text-sm text-gray-700">of</span>
            <span class="font-medium">{{ totalPages || 0 }}</span>
          </div>
          <button
            @click="nextPage"
            :disabled="currentPage >= totalPages"
            class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 hover:border hover:border-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
        </div>
    </div>
    <div
      v-else-if="activeTab === 'metrics'"
      class="bg-white p-4 rounded-lg shadow-md mt-4 w-full mx-2"
    >
      <div class="p-4 pb-0 gap-3 flex justify-between items-start">
        <!-- <p class="text-lg font-semibold text-[#121212]">Metrics Trends</p> -->
        <div
          class="flex gap-2 w-full sm:w-auto min-w-[200px] max-w-full text-sm md:text-base"
        >
          <v-select
            v-model="selectedMetrics"
            :items="availableMetrics"
            multiple
            chips
            label="Select Metrics"
            class="min-w-[200px] text-xs md:text-base"
            outlined
            deletable-chips
          ></v-select>
        </div>
      </div>
      <div
        v-if="chartData?.length"
        class="legend-container flex flex-wrap gap-2 mb-4 px-4"
      >
        <div
          v-for="metric in selectedMetrics"
          :key="metric"
          class="flex items-center gap-2"
        >
          <div
            class="w-6 h-[2px]"
            :style="{ backgroundColor: metricColors[metric] }"
          ></div>
          <span class="text-sm text-gray-600">
            {{ availableMetrics.find((m) => m.value === metric)?.text }}
          </span>
        </div>
      </div>
      <div
        v-if="isChartLoading"
        class="w-full h-20 flex justify-center items-center bg-white rounded-lg p-4"
      >
        <v-progress-circular
          model-value="20"
          color="primary"
          :size="30"
          indeterminate
        ></v-progress-circular>
      </div>
      <div
        v-else-if="!chartData || chartData?.length === 0"
        class="flex justify-center items-center py-8"
      >
        <p class="text-gray-500">No data available</p>
      </div>
      <div
        v-show="!isChartLoading && chartData?.length"
        class="relative h-[400px] w-full"
      >
        <canvas ref="metricsChart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
class MetricsCache {
  constructor() {
    this.cache = new Map();
    this.initializeCache();
  }

  initializeCache() {
    try {
      const savedCache = localStorage.getItem('metricsCache');
      if (savedCache) {
        const parsedCache = JSON.parse(savedCache);
        Object.entries(parsedCache).forEach(([key, value]) => {
          this.cache.set(key, value);
        });
      }
    } catch (error) {
      console.error('Error initializing cache:', error);
    }
  }

  // Generate cache key for a specific location
  static generateLocationKey(year, month, wid) {
    return `${year}-${month}-${wid}`;
  }

  // Check if data should be cached (only cache previous months)
  static shouldCache(year, month) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    return (year < currentYear) || (year === currentYear && month < currentMonth);
  }

  // Get cached data for a specific location
  getLocationData(year, month, wid) {
    const key = MetricsCache.generateLocationKey(year, month, wid);
    return this.cache.get(key);
  }

  // Get cached data for multiple locations
  getLocationsData(year, month, wids) {
    return wids.map(wid => this.getLocationData(year, month, wid))
      .filter(Boolean); // Remove null/undefined entries
  }

  // Store data for a specific location
  setLocationData(year, month, wid, data) {
    if (!MetricsCache.shouldCache(year, month)) return;

    const key = MetricsCache.generateLocationKey(year, month, wid);
    this.cache.set(key, data);
    this.persistCache();
  }

  // Store data for multiple locations
  setLocationsData(year, month, metricsData) {
    if (!MetricsCache.shouldCache(year, month)) return;

    metricsData.forEach(metric => {
      if (metric.location?.wid) {
        this.setLocationData(year, month, metric.location.wid, metric);
      }
    });
  }

  persistCache() {
    try {
      const cacheObject = Object.fromEntries(this.cache);
      localStorage.setItem('metricsCache', JSON.stringify(cacheObject));
    } catch (error) {
      console.error('Error persisting cache:', error);
    }
  }

  // Debug method to view cache contents
  debugCache() {
    const cacheContents = {};
    this.cache.forEach((value, key) => {
      cacheContents[key] = value;
    });
    console.log('Cache contents:', cacheContents);
    return cacheContents;
  }

  clear() {
    this.cache.clear();
    localStorage.removeItem('metricsCache');
  }
}
import {
  collection,
  onSnapshot,
  query,
  where,
  getDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";
import { auth, db } from "@/utils/firebase.utils";
import ToolTip from "@/components/ui/ToolTip.vue";
import Chart from "chart.js/auto";

export default {
  components: { ToolTip },
  props: {},
  data() {
    return {
      stores: [],
      showStoreDropdown: false,
      selectedWID: null,
      selectedWIDs: [],
      metricsData: {},
      isLoading: false,
      isChartLoading: false,
      dateRanges: ["Last 7 days", "Last 28 days", "Last 60 days"],
      selectedDateRange: "Last 7 days",
      sortBy: "location",
      sortDesc: false,
      locationTitles: {},
      gmb_logo: "",
      dateOptions: [],
      selectedDate: null,
      isLoadingDates: false,
      metricsChart: null,
      chartData: [],
      selectedMetrics: [
        "desktop_maps_impressions",
        "mobile_maps_impressions",
        "desktop_search_impressions",
        "mobile_search_impressions",
        "direction_requests",
        "call_clicks",
        "website_clicks",
      ],
      availableMetrics: [
        { text: "Desktop Maps", value: "desktop_maps_impressions" },
        { text: "Mobile Maps", value: "mobile_maps_impressions" },
        { text: "Desktop Search", value: "desktop_search_impressions" },
        { text: "Mobile Search", value: "mobile_search_impressions" },
        { text: "Direction Requests", value: "direction_requests" },
        { text: "Call Clicks", value: "call_clicks" },
        { text: "Website Clicks", value: "website_clicks" },
        { text: "Bookings", value: "bookings" },
        { text: "Food Orders", value: "food_orders" },
        { text: "Food Menu Clicks", value: "food_menu_clicks" },
      ],
      activeTab: "performance",

      metricColors: {
        desktop_maps_impressions: "#FF6384",
        mobile_maps_impressions: "#36A2EB",
        desktop_search_impressions: "#FFCE56",
        mobile_search_impressions: "#4BC0C0",
        direction_requests: "#9966FF",
        call_clicks: "#FF9F40",
        website_clicks: "#50E3C2",
        bookings: "#F95D6A",
        food_orders: "#845EC2",
        food_menu_clicks: "#D65DB1",
      },
      locationSearch: "",
      currentPage: 1,
      itemsPerPage: 10,
      paginatedWIDs: [],
      metricsCache: new MetricsCache(),
    };
  },
  computed: {
    isCurrentMonth() {
      const currentDate = new Date();
      return (
        this.selectedDate?.year === currentDate.getFullYear() &&
        this.selectedDate?.month === currentDate.getMonth() + 1
      );
    },
    totalPages() {
      return Math.ceil(this.selectedWIDs.length / this.itemsPerPage) || 0;
    },
    filteredStores() {
      console.log("filteredStores:", this.stores);
      if (!this.locationSearch) {
        return this.stores;
      }

      const searchTerm = this.locationSearch.toLowerCase();
      return this.stores.filter(
        (store) =>
          store.name?.toLowerCase().includes(searchTerm) ||
          store.sub_locality?.toLowerCase().includes(searchTerm) ||
          store.city?.toLowerCase().includes(searchTerm)
      );
    },
    dateRange() {
      let endDate = new Date();
      let startDate = new Date();

      switch (this.selectedDateRange) {
        case "Last 7 days":
          startDate.setDate(endDate.getDate() - 7);
          break;
        case "Last 28 days":
          startDate.setDate(endDate.getDate() - 28);
          break;
        case "Last 60 days":
          startDate.setDate(endDate.getDate() - 60);
          break;
        case "Last 90 days":
          startDate.setDate(endDate.getDate() - 90);
          break;
      }

      return { startDate, endDate };
    },
  },
  watch: {
    selectedWIDs(newVal) {
      if (!newVal.length) {
        this.currentPage = 1;
        this.updatePaginatedWIDs();
        this.fetchDataBasedOnTab();
        this.chartData = [];
        if (this.metricsChart) {
          this.metricsChart.destroy();
          this.metricsChart = null;
        }
      }
    },
    selectedMetrics() {
      this.updateChart();
    },
    activeTab(newVal) {
      if (newVal === "metrics") {
        // Ensure chart is rendered when switching to metrics tab
        this.$nextTick(() => {
          this.updateChart();
        });
      }
    },
  },
  async created() {
    this.generateDateOptions();
  },
  methods: {
    updatePaginatedWIDs() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      // console.log('start:', start, 'end:', end)
      this.paginatedWIDs = this.selectedWIDs.slice(start, end);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.updatePaginatedWIDs();
        this.fetchDataBasedOnTab();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.updatePaginatedWIDs();
        this.fetchDataBasedOnTab();
      }
    },
    generateRandomPath() {
      // Generate random points for the path
      const points = [];
      const segments = 8;
      for (let i = 0; i <= segments; i++) {
        const x = (i * 100) / segments;
        const y = 150 + Math.random() * 100 - 50; // Random y value around 150
        points.push(`${x},${y}`);
      }
      return points.join(" L ");
    },
    async handleTabChange(tab) {
      if (this.metricsChart) {
        this.metricsChart.destroy();
        this.metricsChart = null;
      }
      this.activeTab = tab;
      await this.fetchDataBasedOnTab();
    },
    async fetchDataBasedOnTab() {
      if (this.activeTab === "performance") {
        await this.fetchMetricsForAllLocations();
      } else {
        await this.fetchChartMetrics();
      }
    },
    async fetchChartMetrics() {
      try {
        if (!this.selectedWIDs.length) {
          this.chartData = [];
          return;
        }

        this.isChartLoading = true;
        // Initialize base payload
        let basePayload = {
          uid: this.currentUID,
          wid: this.selectedWIDs,
          accounts: [],
          year: this.selectedDate.year,
          month: this.selectedDate.month,
          source: "graph",
        };

        // Process all WIDs first
        const connectedAppsPromises = this.selectedWIDs.map(async (wid) => {
          const connectedAppsRef = collection(
            db,
            "users",
            this.currentUID,
            "fly_workspaces",
            wid,
            "connected-apps"
          );
          const gbpDoc = await getDocs(
            query(
              connectedAppsRef,
              where("__name__", "==", "google_business_profile")
            )
          );
          return { wid, gbpDoc };
        });

        // Wait for all promises to resolve
        const connectedAppsResults = await Promise.all(connectedAppsPromises);

        // Process all results before making the backend call
        for (const { wid, gbpDoc } of connectedAppsResults) {
          if (gbpDoc.empty) continue;

          const gbpData = gbpDoc.docs[0].data();
          const accounts = gbpData.accounts;
          if (!accounts?.length) continue;

          const accountId = accounts[0].id || "";

          // Process all accounts for this WID
          for (const account of accounts) {
            const locations = account.locations;
            if (!locations?.length) continue;

            // Process all locations for this account
            for (const location of locations) {
              basePayload.accounts.push({
                locationId: location.name,
                account: accountId,
                wid,
              });
            }
          }
        }

        // Only proceed if we have accounts to process
        if (basePayload.accounts.length > 0) {
          console.log("Final basePayload:", basePayload);
          const fetchMetricsGraph = httpsCallable(
            functions,
            "fetchPlatformMetrics"
          );
          const result = await fetchMetricsGraph(basePayload);
          console.log("result:", result.data);

          if (result.data.success) {
            this.chartData = result.data.data.metrics_by_date;
            await this.updateChart();
          } else {
            console.warn("No data returned from metrics API");
          }
        } else {
          console.warn("No accounts found to process");
        }
      } catch (error) {
        console.error("Error fetching chart metrics:", error);
      } finally {
        this.isChartLoading = false;
      }
    },

    async updateChart() {
      if (!this.chartData?.length || !this.selectedWIDs.length) {
        if (this.metricsChart) {
          this.metricsChart.destroy();
          this.metricsChart = null;
        }
        return;
      }
      // Ensure we're on metrics tab and have a canvas reference
      if (this.activeTab !== "metrics" || !this.$refs.metricsChart) {
        return;
      }

      // Destroy existing chart if it exists
      if (this.metricsChart) {
        this.metricsChart.destroy();
        this.metricsChart = null;
      }
      const sortedChartData = [...this.chartData].sort((a, b) => new Date(a.date) - new Date(b.date));

      const ctx = this.$refs.metricsChart.getContext("2d");
      const datasets = this.selectedMetrics.map((metric) => ({
        label:
          this.availableMetrics.find((m) => m.value === metric)?.text || metric,
        data: sortedChartData.map((day) => day[metric]),
        borderColor: this.metricColors[metric],
        backgroundColor: this.metricColors[metric], // Add this for tooltip color
        pointBackgroundColor: this.metricColors[metric], // Add this for point color
        pointHoverBackgroundColor: this.metricColors[metric],
        tension: 0,
        fill: false,
      }));

      this.metricsChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: sortedChartData.map((day) =>
            new Date(day.date).toLocaleDateString()
          ),
          datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                // Auto-generate steps based on data
                autoSkip: false,
                // Include 0 and calculate max based on data
                callback: function (value) {
                  return value.toFixed(0); // Show whole numbers only
                },
              },
              grace: "5%", // Add 5% padding to the scale
            },
          },
          plugins: {
            legend: {
              // position: "top",
              display: false,
            },
            tooltip: {
              enabled: true,
              titleFont: {
                size: 16,
              },
              bodyFont: {
                size: 14,
              },
            },
          },
        },
      });
    },

    generateDateOptions() {
      const options = [];
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();
      // Generate options for the past 12 months
      for (let i = 0; i < 12; i++) {
        const date = new Date(currentYear, currentMonth - i, 1);
        const year = date.getFullYear();
        const monthName = date.toLocaleString("default", { month: "long" });
        options.push({
          text: `${monthName} ${year}`,
          value: {
            year: year,
            month: date.getMonth() + 1,
          },
        });
      }
      this.dateOptions = options;
      this.selectedDate = options[0].value;
    },
    sortData(column) {
      // If clicking the same column, reverse the sort direction
      if (this.sortBy === column) {
        this.sortDesc = !this.sortDesc;
      } else {
        // New column, set it as sort key and default to ascending
        this.sortBy = column;
        this.sortDesc = false;
      }
      console.log("metricsData:", this.metricsData);
      // Sort the metricsData array
      this.metricsData.sort((a, b) => {
        let aVal = column === "location" ? a.location.sub_locality : a[column];
        let bVal = column === "location" ? b.location.sub_locality : b[column];

        // Handle numeric values
        if (typeof aVal === "number" && typeof bVal === "number") {
          return this.sortDesc ? bVal - aVal : aVal - bVal;
        }

        // Handle string values
        aVal = String(aVal).toLowerCase();
        bVal = String(bVal).toLowerCase();
        if (aVal < bVal) return this.sortDesc ? 1 : -1;
        if (aVal > bVal) return this.sortDesc ? -1 : 1;
        return 0;
      });
    },
    async fetchGMBLogo() {
      if (this.gmb_logo) {
        return;
      }
      try {
        const platformDoc = await getDoc(
          doc(db, "fly_platforms", "google_business_profile")
        );
        if (platformDoc.exists()) {
          const logoUrl = platformDoc.data().logo_url;
          this.gmb_logo = logoUrl;
        }
      } catch (error) {
        console.error("Error fetching platform logo:", error);
      }
    },
    getTotalMetric(metricType) {
      if (!this.metricsData || !Array.isArray(this.metricsData)) return null;

      return this.metricsData.reduce((total, metric) => {
        switch (metricType) {
          case "maps":
            return (
              total +
              (metric.desktop_maps_impressions || 0) +
              (metric.mobile_maps_impressions || 0)
            );
          case "search":
            return (
              total +
              (metric.desktop_search_impressions || 0) +
              (metric.mobile_search_impressions || 0)
            );
          case "directions":
            return total + (metric.direction_requests || 0);
          case "calls":
            return total + (metric.call_clicks || 0);
          case "website":
            return total + (metric.website_clicks || 0);
          case "bookings":
            return total + (metric.bookings || 0);
          case "food_orders":
            return total + (metric.food_orders || 0);
          case "food_menu":
            return total + (metric.food_menu_clicks || 0);
          default:
            return total;
        }
      }, 0);
    },
    getTooltipMessage(metric, type = "default") {
      const messages = {
        maps: {
          desktop: "Business impressions on Google Maps on Desktop devices",
          mobile: "Business impressions on Google Maps on Mobile devices",
          combined: "Total Maps impressions across all locations",
        },
        search: {
          desktop: "Business impressions on Google Search on Desktop devices",
          mobile: "Business impressions on Google Search on Mobile devices",
          combined: "Total Search impressions across all locations",
        },
        directions: {
          default: "Total direction requests across all locations",
          combined: "Total direction requests across all locations",
        },
        calls: {
          default:
            "The number of times the business profile call button was clicked",
          combined: "Total call clicks across all locations",
        },
        website: {
          default:
            "The number of times the business profile website was clicked",
          combined: "Total website clicks across all locations",
        },
        bookings: {
          default:
            "The number of bookings made from the business profile via Reserve with Google",
          combined: "Total bookings across all locations",
        },
        food_orders: {
          default:
            "The number of food orders received from the business profile",
          combined: "Total food orders across all locations",
        },
        food_menu: {
          default:
            "The number of clicks to view or interact with the menu content on the business profile",
          combined:
            "Total number of clicks to interact with the menu content across all locations",
        },
      };

      // Return appropriate message based on metric and type
      if (type === "desktop") {
        return messages[metric]?.desktop || messages[metric]?.default;
      } else if (type === "mobile") {
        return messages[metric]?.mobile || messages[metric]?.default;
      } else if (type === "combined") {
        return messages[metric]?.combined || messages[metric]?.default;
      } else {
        return messages[metric]?.default;
      }
    },
    handleClickOutside(event) {
      const profileDropdown = this.$refs.showStoreDropdown;
      if (profileDropdown && !profileDropdown.contains(event.target)) {
        this.closeProfileDropdown();
      }
    },
    closeProfileDropdown() {
      if (this.showStoreDropdown) {
        this.showStoreDropdown = false;
      }
    },
    toggleStoreDropdown(event) {
      this.showStoreDropdown = !this.showStoreDropdown;
      event.stopPropagation();
    },
    async fetchStorePosts(profile) {
      if (profile.checked) {
        this.selectedWIDs.push(profile.wid);
      } else {
        this.selectedWIDs = this.selectedWIDs.filter(
          (wid) => wid !== profile.wid
        );
      }
      this.selectedWID = profile.wid;
      this.showStoreDropdown = false;
      // Clear chart data if no locations selected
      if (this.selectedWIDs.length === 0) {
        this.chartData = [];
        if (this.metricsChart) {
          this.metricsChart.destroy();
          this.metricsChart = null;
        }
        return; // Don't fetch data if no locations selected
      }
      this.updatePaginatedWIDs();
      await this.fetchDataBasedOnTab();
    },
    async fetchMetricsForAllLocations() {
      try {
        this.isLoading = true;
        const days = this.selectedDateRange.match(/\d+/)[0];
        let wids = this.paginatedWIDs ? this.paginatedWIDs : this.selectedWIDs;
        if(!wids?.length) {
          this.metricsData = [];
          return;
        }
        let cachedData = []
        if (!this.isCurrentMonth) {
          // Try to get data from cache first
          cachedData = this.metricsCache.getLocationsData(
            this.selectedDate.year,
            this.selectedDate.month,
            wids
          );
          console.log('cachedData:', cachedData)
          // If we have all locations in cache, use cached data
          if (cachedData.length === wids.length) {
            this.metricsData = this.deserializeMetricsData(cachedData);
            this.sortData("location");
            this.isLoading = false;
            return;
          }
          // Find which locations need to be fetched
          const cachedWids = new Set(cachedData.map(d => d.location.wid));
          // console.log('cachedWids:', cachedWids)
          wids = wids.filter(wid => !cachedWids.has(wid));
          // console.log('wids:', wids)
        }

        // Fetch all connected apps data in parallel
        const connectedAppsPromises = wids?.map(async (wid) => {
          const connectedAppsRef = collection(
            db,
            "users",
            this.currentUID,
            "fly_workspaces",
            wid,
            "connected-apps"
          );
          const gbpDoc = await getDocs(
            query(
              connectedAppsRef,
              where("__name__", "==", "google_business_profile")
            )
          );
          return { wid, gbpDoc };
        });

        const connectedAppsResults = await Promise.all(connectedAppsPromises);
        // Process results and build payloads
        const locationTitlesMapping = {};
        const payloads = [];

        connectedAppsResults.forEach(({ wid, gbpDoc }) => {
          if (gbpDoc.empty) return;

          const gbpData = gbpDoc.docs[0].data();
          const accounts = gbpData.accounts;
          if (!accounts?.length) return;

          const accountId = accounts[0].id || "";

          // Use for...of instead of forEach for better performance with early returns
          for (const account of accounts) {
            const locations = account.locations;
            if (!locations?.length) continue;

            // Pre-calculate common values
            const basePayload = {
              uid: this.currentUID,
              wid,
              platform: "google_business_profile",
              account: accountId,
              days: parseInt(days),
              resource_type: "detailed",
              year: this.selectedDate.year,
              month: this.selectedDate.month,
            };

            // Use for...of for better performance
            for (const location of locations) {
              locationTitlesMapping[wid] = {
                title: location.title,
                locationId: location.name,
                wid,
              };
              payloads.push({
                ...basePayload,
                location: location.name,
              });
            }
          }
        });
        // console.log('payloads:', payloads)
        // Fetch all metrics in parallel
        const fetchPlatformMetrics = httpsCallable(
          functions,
          "fetchPlatformMetrics"
        );
        const results = await Promise.all(
          payloads.map((payload) => fetchPlatformMetrics(payload))
        );

        // Process results
        const newMetricsData = results
          .map((result, index) => {
            const metrics = result.data;
            if (!this.hasMetrics(metrics)) return null;
            const locationWid = payloads[index].wid;
            const store = this.stores.find(store => store.wid === locationWid) || {};

            return {
              location: {
                wid: locationWid,
                name: store.name || "",
                sub_locality: store.sub_locality || "",
                city: store.city || "",
                state: store.state || "",
                storeLocation: store.storeLocation || "",
                title: locationTitlesMapping[locationWid]?.title || "",
              },
              ...this.extractMetrics(metrics),
            };
          })
          .filter(Boolean);
        if (!this.isCurrentMonth) {
          this.metricsCache.setLocationsData(
            this.selectedDate.year,
            this.selectedDate.month,
            this.serializeMetricsData(newMetricsData)
          );
        }

        // Combine with existing cached data if any
        this.metricsData = !this.isCurrentMonth ? 
          [...(cachedData || []), ...this.deserializeMetricsData(newMetricsData)] :
          newMetricsData;

        this.sortData("location");
      } catch (error) {
        console.error("Error fetching metrics:", error);
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    // Serialize metrics data for storage
    serializeMetricsData(data) {
      return data.map(metric => ({
        ...metric,
        location: {
          wid: metric.location.wid,
          name: metric.location.name,
          sub_locality: metric.location.sub_locality,
          city: metric.location.city,
          state: metric.location.state,
          storeLocation: metric.location.storeLocation,
          title: metric.location.title,
        },
        // Include only numeric metrics to save space
        desktop_maps_impressions: metric.desktop_maps_impressions,
        mobile_maps_impressions: metric.mobile_maps_impressions,
        desktop_search_impressions: metric.desktop_search_impressions,
        mobile_search_impressions: metric.mobile_search_impressions,
        direction_requests: metric.direction_requests,
        call_clicks: metric.call_clicks,
        website_clicks: metric.website_clicks,
        bookings: metric.bookings,
        food_orders: metric.food_orders,
        food_menu_clicks: metric.food_menu_clicks,
      }));
    },

    // Deserialize metrics data from storage
    deserializeMetricsData(data) {
      return data.map(metric => ({
        ...metric,
      }));
    },
    async fetchStores() {
      const uid = this.currentUID;
      const storeRef = collection(db, "users", uid, "fly_workspaces");
      onSnapshot(storeRef, async (querySnapshot) => {
        this.stores = [];
        this.selectedWIDs = [];
        querySnapshot.forEach((doc) => {
          let storeData = doc.data();
          // storeData.checked = true;
          if (
            storeData.storeWorkspace &&
            storeData.admin === this.WID &&
            storeData.connected_apps?.includes("google_business_profile")
          ) {
            storeData.wid = doc.id;
            storeData.type = "store";
            storeData.display_name = `${storeData.sub_locality} - ${storeData.name}`;
            storeData.checked = true;
            this.stores.push(storeData);
            this.selectedWIDs.push(doc.id);
          }
        });
        this.updatePaginatedWIDs();
        // Fetch metrics for all initially selected stores
        if (this.selectedWIDs.length > 0) {
          await this.fetchDataBasedOnTab();
        }
      });
    },
    async selectDateRange() {
      if (!this.selectedDate) return;
      await this.fetchDataBasedOnTab();
    },
    hasMetrics(metrics) {
      return Object.keys(metrics || {}).some(
        (key) =>
          metrics[key] !== undefined &&
          [
            "desktop_maps_impressions",
            "mobile_maps_impressions",
            "desktop_search_impressions",
            "mobile_search_impressions",
            "direction_requests",
            "call_clicks",
            "website_clicks",
            "bookings",
            "food_orders",
            "food_menu_clicks",
          ].includes(key)
      );
    },
    extractMetrics(metrics) {
      return {
        desktop_maps_impressions: metrics?.desktop_maps_impressions || 0,
        mobile_maps_impressions: metrics?.mobile_maps_impressions || 0,
        desktop_search_impressions: metrics?.desktop_search_impressions || 0,
        mobile_search_impressions: metrics?.mobile_search_impressions || 0,
        direction_requests: metrics?.direction_requests || 0,
        call_clicks: metrics?.call_clicks || 0,
        website_clicks: metrics?.website_clicks || 0,
        bookings: metrics?.bookings || 0,
        food_orders: metrics?.food_orders || 0,
        food_menu_clicks: metrics?.food_menu_clicks || 0,
        total_impressions: metrics?.total_impressions || 0,
        total_clicks: metrics?.total_clicks || 0,
        total_posts: metrics?.total_posts || 0,
      };
    },
  },

  async mounted() {
    this.fetchGMBLogo();
    await this.fetchStores();
    this.fetchChartMetrics();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
    if (this.metricsChart) {
      this.metricsChart.destroy();
      this.metricsChart = null;
    }
  },
};
</script>

<style scoped>
.text-xs {
  font-size: 0.75rem;
}
.text-center {
  text-align: center;
}
img {
  object-fit: cover;
}
.cursor-pointer {
  cursor: pointer;
}
.hover\:bg-gray-100:hover {
  background-color: #f7fafc;
}
.leading-tight {
  line-height: 1.25;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
}
.skeleton-loader {
  display: inline-block;
  width: 50px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite;
}
.overflow-x-auto {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}
/* Optional: Custom scrollbar styling */
.overflow-x-auto::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.overflow-x-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tooltip-container {
  position: relative;
  z-index: 40;
}
.v-tooltip__content {
  z-index: 60 !important;
  position: fixed !important;
}
.v-icon {
  z-index: 40;
  position: relative;
}

.showStoreDropdown {
  background-color: white; /* Ensure solid background */
  z-index: 50;
  position: relative;
}

.date-select {
  position: relative;
  z-index: 40;
}

button.tabs {
  border-width: 2px;
  border-style: solid;
  border-color: #8056de;
}
button.tabs.active {
  background-color: #8056de;
  color: white;
}
.metrics-select {
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* This ensures the v-select dropdown menu appears above everything */
:deep(.v-menu__content) {
  z-index: 60 !important;
}
</style>
