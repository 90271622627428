<template>
  <v-menu v-if="isAuth && (shouldRenderMenu || checkGrantedWorkspace)" offset-y left v-model="menu" rounded="xl"
    class="relative  z-[100000000] w-[305px] h-[167px]  rounded-[20px] shadow" max-width="350px">
    <template v-slot:activator="{ on }">
      <div v-on="on" flat class="cursor-pointer" :max-width="isMobile ? '250px' : '600px'" :disabled="isLoading">
        <v-skeleton-loader v-if="isLoading" type="list-item-avatar"></v-skeleton-loader>
        <v-list-item class="flex items-center justify-center">
          <div class="w-[171px] h-[39px]  rounded-[32px] flex items-center justify-between my-2">
            <!-- Flex container for logo and name -->
            <div class="flex items-center ml-2">
              <!-- <v-list-item-avatar v-if="currentWorkspaceLogo" class="mr-2">
                <img :src="currentWorkspaceLogo" alt="Workspace Logo" class="h-6 w-6 rounded-full">
              </v-list-item-avatar> -->
              <div class="text-sm font-medium text-balck">
                <div class="truncate">{{ trimmedWorkspaceName }}</div>
                <div class="text-[11px] text-[#8056dd] font-medium ">Workspace</div>
              </div>
            </div>
            <div class="text-white mr-2">
              <v-icon class="drop text-[#0f121c]
text-base
font-medium" color="black">mdi-chevron-down</v-icon>
            </div>
          </div>
        </v-list-item>
      </div>

    </template>
    <v-list class="pt-0 workspace-list">
      <v-list-item-group class="p-4 company-list " :value="WID" mandatory>
        <v-list-item v-for="(item, i) in workspaces" :key="i" class="pb-0" :value="item.id"
          @click="changeWorkspace(item)" :class="{ 'selected-workspace': checkSelected(item.id) }">
          <v-list-item-avatar v-if="item.logo_original">
            <img :src="item.logo_original" alt="Workspace Logo" class="h-8 w-8 rounded-full">
            <!-- Adjust size as needed -->
          </v-list-item-avatar>
          <div class="text-lg font-medium text-white">
            {{ truncateName(item.name) }}
            <div v-if="item.type == 'user'" class="text-xs font-normal text-slate-300">
              Owner
            </div>
            <div v-else class="text-xs font-normal text-slate-500">Shared</div>
            <!-- <v-chip inline v-if="item.id === 'default'" small color="primary"
                >Owner</v-chip
              > -->
          </div>
          <v-list-item-content>
            <v-list-item-title></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="item.type == 'user'">

            <div class="relative" v-on:click.stop>
              <button @click="toggleDropdown(i)">
                <!-- Three dot icon -->
                <v-icon class="mr-2" color="grey lighten-1">
                  mdi-dots-vertical
                </v-icon>
              </button>
              <div v-if="openDropdownIndex === i"
                class="absolute right-0 top-full z-40 w-40 space-y-1 rounded-sm border border-stroke bg-white p-1.5 shadow-default dark:border-strokedark dark:bg-boxdark">
                <button
                  class="flex w-full items-center gap-2 rounded-sm py-1.5 px-4 text-left text-sm hover:bg-gray dark:hover:bg-meta-4"
                  @click="populateWorkspaceName(item)">
                  <svg class="fill-current" width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_62_9787)">
                      <path
                        d="M15.55 2.97499C15.55 2.77499 15.475 2.57499 15.325 2.42499C15.025 2.12499 14.725 1.82499 14.45 1.52499C14.175 1.24999 13.925 0.974987 13.65 0.724987C13.525 0.574987 13.375 0.474986 13.175 0.449986C12.95 0.424986 12.75 0.474986 12.575 0.624987L10.875 2.32499H2.02495C1.17495 2.32499 0.449951 3.02499 0.449951 3.89999V14C0.449951 14.85 1.14995 15.575 2.02495 15.575H12.15C13 15.575 13.725 14.875 13.725 14V5.12499L15.35 3.49999C15.475 3.34999 15.55 3.17499 15.55 2.97499ZM8.19995 8.99999C8.17495 9.02499 8.17495 9.02499 8.14995 9.02499L6.34995 9.62499L6.94995 7.82499C6.94995 7.79999 6.97495 7.79999 6.97495 7.77499L11.475 3.27499L12.725 4.49999L8.19995 8.99999ZM12.575 14C12.575 14.25 12.375 14.45 12.125 14.45H2.02495C1.77495 14.45 1.57495 14.25 1.57495 14V3.87499C1.57495 3.62499 1.77495 3.42499 2.02495 3.42499H9.72495L6.17495 6.99999C6.04995 7.12499 5.92495 7.29999 5.87495 7.49999L4.94995 10.3C4.87495 10.5 4.92495 10.675 5.02495 10.85C5.09995 10.95 5.24995 11.1 5.52495 11.1H5.62495L8.49995 10.15C8.67495 10.1 8.84995 9.97499 8.97495 9.84999L12.575 6.24999V14ZM13.5 3.72499L12.25 2.49999L13.025 1.72499C13.225 1.92499 14.05 2.74999 14.25 2.97499L13.5 3.72499Z"
                        fill="" />
                    </g>
                    <defs>
                      <clipPath id="clip0_62_9787">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Edit
                </button>
                <button
                  class="flex w-full items-center gap-2 rounded-sm py-1.5 px-4 text-left text-sm hover:bg-gray dark:hover:bg-meta-4"
                  @click="deleteWorkspace(item)" v-if="item.wid !== 'default'">
                  <svg class="fill-current" width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.225 2.20005H10.3V1.77505C10.3 1.02505 9.70005 0.425049 8.95005 0.425049H7.02505C6.27505 0.425049 5.67505 1.02505 5.67505 1.77505V2.20005H3.75005C3.02505 2.20005 2.42505 2.80005 2.42505 3.52505V4.27505C2.42505 4.82505 2.75005 5.27505 3.22505 5.47505L3.62505 13.75C3.67505 14.775 4.52505 15.575 5.55005 15.575H10.4C11.425 15.575 12.275 14.775 12.325 13.75L12.75 5.45005C13.225 5.25005 13.55 4.77505 13.55 4.25005V3.50005C13.55 2.80005 12.95 2.20005 12.225 2.20005ZM6.82505 1.77505C6.82505 1.65005 6.92505 1.55005 7.05005 1.55005H8.97505C9.10005 1.55005 9.20005 1.65005 9.20005 1.77505V2.20005H6.85005V1.77505H6.82505ZM3.57505 3.52505C3.57505 3.42505 3.65005 3.32505 3.77505 3.32505H12.225C12.325 3.32505 12.425 3.40005 12.425 3.52505V4.27505C12.425 4.37505 12.35 4.47505 12.225 4.47505H3.77505C3.67505 4.47505 3.57505 4.40005 3.57505 4.27505V3.52505V3.52505ZM10.425 14.45H5.57505C5.15005 14.45 4.80005 14.125 4.77505 13.675L4.40005 5.57505H11.625L11.25 13.675C11.2 14.1 10.85 14.45 10.425 14.45Z"
                      fill="" />
                    <path
                      d="M8.00005 8.1001C7.70005 8.1001 7.42505 8.3501 7.42505 8.6751V11.8501C7.42505 12.1501 7.67505 12.4251 8.00005 12.4251C8.30005 12.4251 8.57505 12.1751 8.57505 11.8501V8.6751C8.57505 8.3501 8.30005 8.1001 8.00005 8.1001Z"
                      fill="" />
                    <path
                      d="M9.99994 8.60004C9.67494 8.57504 9.42494 8.80004 9.39994 9.12504L9.24994 11.325C9.22494 11.625 9.44994 11.9 9.77494 11.925C9.79994 11.925 9.79994 11.925 9.82494 11.925C10.1249 11.925 10.3749 11.7 10.3749 11.4L10.5249 9.20004C10.5249 8.87504 10.2999 8.62504 9.99994 8.60004Z"
                      fill="" />
                    <path
                      d="M5.97497 8.60004C5.67497 8.62504 5.42497 8.90004 5.44997 9.20004L5.62497 11.4C5.64997 11.7 5.89997 11.925 6.17497 11.925C6.19997 11.925 6.19997 11.925 6.22497 11.925C6.52497 11.9 6.77497 11.625 6.74997 11.325L6.57497 9.12504C6.57497 8.80004 6.29997 8.57504 5.97497 8.60004Z"
                      fill="" />
                  </svg>
                  Delete
                </button>
              </div>
            </div>
          </v-list-item-action>

        </v-list-item>

        <div class="flex items-center justify-around w-[250px] h-12 bg-slate-100 rounded-3xl cursor-pointer my-1"
          @click="checkWorkspaceLimit" v-if="(shouldRenderMenu)">
          <div class="text-xs font-medium text-slate-500">Add workspace</div>
          <div class="relative w-5 h-5 cursor-pointer">
            <img src="@/assets/img/plus_work.svg" />
          </div>
        </div>

        <v-dialog v-model="dialog" persistent max-width="400px">
          <addworkspace title="Add workspace" @close="dialog = false" @submit="addWorkspace()">
            <addwrokspaceinput-vue label="Brand/Business Name" v-model="brandName" rules="maxworkspacename"
              :error-messages="errorMessage" />
            <v-card class="cursor-pointer mt-2">
              <v-text-field v-model="website" label="Website/Social Profile/Page URL" prepend-icon="mdi-web" type="url"
                class="ml-2 cursor-pointer" />
            </v-card>

            <!-- Business Description Field -->
            <v-card class="cursor-pointer mt-2">
              <v-textarea v-model="businessDescription" label="Business Description" prepend-icon="mdi-text" auto-grow
                />
            </v-card>


            <!-- <v-card class="cursor-pointer mt-2">
              <v-text-field v-model="primaryEmail" label="Primary Email" prepend-icon="mdi-email" type="email"
                class="ml-2 cursor-pointer"></v-text-field>
            </v-card> -->
            <v-card class="cursor-pointer mt-2 mb-2">
              <v-file-input v-model="brandLogo" label="Upload Brand Logo" prepend-icon="mdi-upload" accept="image/*"
                class="ml-2 cursor-pointer"></v-file-input>
            </v-card>
            <v-card class="mt-2 p-2">
              <v-combobox v-model="countryCode" :items="countryOptions" label="Country" item-text="name"
                item-value="value" clearable return-object hide-selected dense >
              </v-combobox>
            </v-card>
            <v-card class="mt-2 p-2">
              <v-combobox
  v-model="timezone"
  :items="timezoneOptions"
  label="Timezone"
  item-text="label" 
  item-value="value" 
  prepend-icon="mdi-earth"
  clearable
  return-object
  hide-selected
  dense
  class="ml-2 cursor-pointer"
>
</v-combobox>
            </v-card>
            <v-card class="cursor-pointer mt-2">
  <v-text-field
    v-model="primaryEmail"
    label="Primary Email"
    prepend-icon="mdi-email"
    type="email"
    class="ml-2 cursor-pointer"
  ></v-text-field>
</v-card>
            <v-checkbox v-model="sendWeeklyReport"
              label="Send weekly social media performance report to primary email"></v-checkbox>
              <vue-tel-input-vuetify
  class="bg-[#f2f6fa] flex flex-row gap-3 outline-[#8056DE] md:w-[309px] h-14 rounded-[26px] mt-2 pb-3 mb-3"
  mode="international"
  v-model="phone_number"
  :validate-on-blur="true"
>
  <input
    type="text"
    class="bg-[#f2f6fa] flex flex-row gap-3 w-full outline-[#8056DE] md:w-[309px] h-12 items-start rounded-[26px]"
    placeholder="Phone Number"
  />
</vue-tel-input-vuetify>

            <v-checkbox v-model="sendWeeklyReportonphone"
              label="Send weekly social media performance report to primary phone number"></v-checkbox>
          </addworkspace>
        </v-dialog>

        <v-dialog v-model="limitDialog" persistent max-width="400px">
          <v-card>
            <v-card-title class="headline">Cannot add workspace</v-card-title>
            <v-card-text>
              You've reached the limit to add more brands. Please reach out to
              us at
              <span>
                <a href="mailto:fly@zoomlocal.in">fly@zoomlocal.in</a>
              </span>
              to get more brands added.
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="limitDialog = false">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-list-item-group>

      <template>
        <v-dialog v-model="nameDialog" persistent max-width="470px">
          <dialog-workspace title="Edit your workspace" @close="closeNameDialog" @submit="editWorkspace">
            <v-card class="cursor-pointer">
              <v-text-field v-model="workspaceName" label="Workspace Name" prepend-icon="mdi-briefcase"
                class="ml-2 cursor-pointer"></v-text-field>
            </v-card>
            <v-card class="cursor-pointer mt-2">
              <v-text-field v-model="website" label="Website/Social Profile/Page URL" prepend-icon="mdi-web" type="url"
                class="ml-2 cursor-pointer" />
            </v-card>
            <!-- Business Description Field -->
            <v-card class="cursor-pointer mt-2">
              <v-textarea v-model="businessDescription" label="Business Description" prepend-icon="mdi-text"
                auto-grow />
            </v-card>
            <!-- Brand Logo Field with Preview and Edit -->
            <v-card class="cursor-pointer mt-2 mb-2">
              <div class="flex">
                <div class="logo-preview-container" style="position: relative; width: 100px; height: 100px;">
                  <img
                    :src="editableWorkspace.logo_original || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTi1p8_WaFcY_wt5qn3Vd0_VpkTtyo9AFeSB0PT3iVz1A&s'"
                    alt="Workspace Logo" class="logo-preview" style="width: 100%; height: 100%; border-radius: 50%;">
                  <v-btn icon @click.stop="triggerFileInput"
                    style="position: absolute; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.5);">
                    <v-icon color="white">mdi-pencil</v-icon>
                  </v-btn>
                </div>
                <!-- Hidden File Input -->
                <input type="file" ref="fileInput" @change="handleLogoChange" accept="image/*" style="display: none;">
                <div>Update Brand logo</div>
              </div>
            </v-card>

            <v-card class="mt-2 p-2">
              <v-combobox v-model="countryCode" :items="countryOptions" label="Country" item-text="name"
                item-value="value" clearable return-object hide-selected dense>
              </v-combobox>
            </v-card>
            <v-card class="mt-2 p-2">
              <v-combobox v-model="timezone" :items="timezoneOptions" label="Timezone" item-text="label"
                item-value="value" prepend-icon="mdi-earth" clearable return-object hide-selected dense
                class="ml-2 cursor-pointer">
              </v-combobox>
            </v-card>
            <v-card class="cursor-pointer mt-2">
  <v-text-field
    v-model="primaryEmail"
    label="Primary Email"
    prepend-icon="mdi-email"
    type="email"
    class="ml-2 cursor-pointer"
  ></v-text-field>
</v-card>
            <v-checkbox v-model="sendWeeklyReport"
              label="Send weekly social media performance report to primary email"></v-checkbox>
              <vue-tel-input-vuetify
  class="bg-[#f2f6fa] flex flex-row gap-3 outline-[#8056DE] md:w-[309px] h-14 rounded-[26px] mt-2 pb-3 mb-3"
  mode="international"
  v-model="phone_number"
>
  <input
    type="text"
    class="bg-[#f2f6fa] flex flex-row gap-3 w-full outline-[#8056DE] md:w-[309px] h-12 items-start rounded-[26px]"
    placeholder="Phone Number"
  />
</vue-tel-input-vuetify>

            <v-checkbox v-model="sendWeeklyReportonphone"
              label="Send weekly social media performance report to primary phone number"></v-checkbox>
          </dialog-workspace>
        </v-dialog>

      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { onAuthStateChanged, signOut } from "firebase/auth";
import UserLogo from "./UserLogo.vue";


import {
  auth,
  colUsers,
  db,
  flyplans,
  functions,
  getSharedWorkspaceRef,
  getWorkspaceDocRef,
  getWorkspaceRef,
} from "@/utils/firebase.utils";
import {
  onSnapshot,
  addDoc,
  collection,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import DialogCard from "@/components/ui/DialogCard.vue";
import InputField from "./ui/form/InputField.vue";
import DialogWorkspace from "@/components/ui/DialogWorkspace.vue";
import Inputworkspace from "@/components/ui/form/Inputworkspace.vue";
import Addworkspace from "./ui/Addworkspace.vue";
import AddwrokspaceinputVue from "./ui/form/Addwrokspaceinput.vue";
import { mapDocs } from "@/utils/common.utils";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ProfileSelectfield from "../components/ui/form/ProfileSelectfield";
import countries from "i18n-iso-countries";
import moment from 'moment-timezone';
import { httpsCallable } from "firebase/functions";

export default {
  components: {
    UserLogo,
    DialogCard,
    InputField,
    DialogWorkspace,
    Inputworkspace,
    Addworkspace,
    AddwrokspaceinputVue,
    ProfileSelectfield
  },
  data() {
    return {
      users: [],
      workspaces: [],
      menu: false,
      subscribe: null,
      isLoading: false,
      dialog: false,
      limitDialog: false,
      nameDialog: false,
      workspaceName: "",
      brandName: "",
      editableWorkspace: {},
      errorMessage: "",
      showAddBtn: true,
      shouldRenderMenu: false,
      brandLogo: null,
      primaryEmail: "",
      emailRules: [
      v => !!v || 'Email is required', // Check for non-empty input
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid' // Validate email format
    ],
    phoneRules: [
      v => !!v || 'Phone number is required', // Checks for non-empty input
      v => (v && v.length >= 10) || 'Phone number must be valid' // Additional validation to ensure length
    ],
      sendWeeklyReport: true,
      phone_number: "",
      sendWeeklyReportonphone: true,
      openDropdownIndex: null,
      website: '',
      websiteError: "",
      businessDescription: '',
      countryCode: '',
      countryOptions: null,
      timezone: '',  // To store the selected timezone
      timezones: moment.tz.names(),
      isEditingWorkspace: false,
      timezone: null, // This will hold the selected timezone
      timezoneOptions: this.getTimezoneOptions(),
    };
  },
  watch: {
    countryCode(newCountryCode) {
    if (newCountryCode) {
      this.updateTimezonesForCountry(newCountryCode); // Update timezones based on country selection
    }
  },
    website(value, oldValue) {
      // Check if the value is already starting with http or https
      if (value && !value.startsWith("http") && oldValue !== value) {
        this.website = `https://${value}`;
      }
      this.validateWebsite();
    },
    menu(newVal) {
      if (!newVal) {
        this.openDropdownIndex = null;
      }
    },
    brandName: {
      handler(val) {
        this.errorMessage = "";
      },
      deep: true,
    },
  },
  computed: {
    async checkGrantedWorkspace() {
      if (!auth.currentUser) {
      console.error('User is not authenticated');
      return false; 
    }
      if (!this.shouldRenderMenu) {
        this.workspaces = this.workspaces.filter((i) => ((i.uid !== auth.currentUser.uid) || (i.uid == auth.currentUser.uid && i.wid == 'default')))
        return true;
      }
      return false
    },
    currentWorkspace() {
      let id = localStorage.getItem("CURRENT_WORKSPACE_ID") || `default__${this.uid}`;
      return this.workspaces.find((i) => i.id === id) || {};
    },
    trimmedWorkspaceName() {
      return this.currentWorkspace.name ? this.currentWorkspace.name.slice(0, 12) : "";
    },
    currentWorkspaceLogo() {
      return this.currentWorkspace.logo_original; // Returns the logo URL of the current workspace
    },
    showUserLogoAndName() {
      return window.innerWidth > 480;
    },
    selectedWorkspace() {
      let workspace = {};
      for (const key in this.workspaces) {
        const checkwid = this.checkWorkspace(this.workspaces[key].id);
        if (
          checkwid &&
          this.workspaces[key].id == `${this.WID}__${this.currentUID}`
        ) {
          workspace = this.workspaces[key];
        }
        if (this.workspaces[key].id === this.WID) {
          workspace = this.workspaces[key];
        }
      }
      return workspace;
    },
  },

  methods: {
    truncateName(name) {
    return name.length > 12 ? name.slice(0, 12) + '...' : name;
  },
    validateWebsite() {
      // Check if the URL starts with https
      if (!this.website.startsWith("https://")) {
        this.websiteError = 'Website must start with "https://"';
        // If it starts with http but not https, let it be (to handle http cases if needed)
        if (this.website.startsWith("http://")) {
          this.websiteError = 'Secure website URL should start with "https://"';
        }
      } else {
        this.websiteError = ""; // Clear error if URL is correct
      }
    },
    getTimezoneOptions() {
      return moment.tz.names().map((tz) => {
        const tzOffset = moment.tz(tz).utcOffset(); // Get timezone offset in minutes
        const offsetHours = Math.floor(Math.abs(tzOffset) / 60);
        const offsetMinutes = Math.abs(tzOffset) % 60;
        const offsetSign = tzOffset >= 0 ? '+' : '-';
        const formattedOffset = `${offsetSign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`; // Format as +hh:mm or -hh:mm

        return {
          label: `(UTC ${formattedOffset}) ${tz}`,
          value: tz,
        };
      });
    },

    // trimmedWorkspaceName() {
    //   return this.selectedWorkspace.name.slice(0, 12);
    // },

    initCountries() {
  countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

  // Create a country options array with full names and codes
  this.countryOptions = Object.entries(countries.getNames("en")).map(
    ([code, name]) => ({
      name: name,
      value: code,
    })
  );
},
updateTimezonesForCountry(country) {
    if (country && country.value) {
      // Get the timezones based on the selected country code
      const timezones = moment.tz.zonesForCountry(country.value.toUpperCase());
      this.timezoneOptions = timezones.map(zone => ({
        label: `(UTC ${moment.tz(zone).format('Z')}) ${zone}`,  // Format timezone label
        value: zone   // The actual value bound to v-model
      }));

      // If only one timezone is available, auto-select it
      if (this.timezoneOptions.length === 1) {
        this.timezone = this.timezoneOptions[0];  // Bind the entire object with label and value
      } else {
        this.timezone = null;  // Clear the timezone if there are multiple options
      }
    } else {
      // If no country is selected, show all available timezones
      const allTimezones = moment.tz.names();
      this.timezoneOptions = allTimezones.map(zone => ({
        label: `(UTC ${moment.tz(zone).format('Z')}) ${zone}`,  // Format timezone label
        value: zone   // The actual value bound to v-model
      }));

      this.timezone = null; // Clear selected timezone when country is deselected
    }
  },
getCountryObject(countryCode) {
  // Find and return the country object (name and code) based on the code
  return this.countryOptions.find((country) => country.value === countryCode);
},

    checkUser(wid) {
      const checkwid = this.checkWorkspace(wid);
      if (checkwid) return false;
      else return true;
    },
    checkSelected(wid) {
      // console.log(wid,"this is the wid")
      const checkwid = this.checkWorkspace(wid);
      if (
        (checkwid && wid == `${this.WID}__${this.currentUID}`) ||
        (!checkwid &&
          wid == this.WID &&
          this.currentUID == this.currentOriginalUID)
      )
        return true;
      else return false;
    },
    checkWorkspace(wid) {
      if (wid?.includes("__")) {
        const parts = wid?.split("__");
        if (parts?.length === 2) {
          const wid = parts[0];
          const senderId = parts[1];
          return { wid, senderId };
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    toggleDropdown(index) {
      // Toggle dropdown visibility based on index
      this.openDropdownIndex = this.openDropdownIndex === index ? null : index;
    },
    async deleteWorkspace(item) {
      if (
        confirm(`Are you sure you want to delete the workspace "${item.name}"?`)
      ) {
        try {
          const userId = auth.currentUser.uid;
          const workspaceDocRef = doc(
            db,
            "users",
            userId,
            "fly_workspaces",
            item.wid
          );

          // Fetch all subcollections and delete them
          const subcollections = await getDocs(
            collection(workspaceDocRef, "brand")
          );
          const deletePromises = subcollections.docs.map((subDoc) =>
            deleteDoc(subDoc.ref)
          );

          await Promise.all(deletePromises);

          // Now delete the workspace document itself
          await deleteDoc(workspaceDocRef);

          this.$snackbar.show("Workspace deleted successfully");
          this.listenUsers();
        } catch (error) {
          console.error("Error deleting workspace: ", error);
          this.$snackbar.show("Failed to delete workspace. Please try again.");
        }
      }
    },
    closeNameDialog() {
      this.nameDialog = false;
      this.isEditingWorkspace = false; // Reset on dialog close
    },
    async populateWorkspaceName(item) {
      this.menu = false;

      try {
        const workspaceDocRef = doc(db, "users", this.uid, "fly_workspaces", item.wid);
        const workspaceDoc = await getDoc(workspaceDocRef);
        if (workspaceDoc.exists()) {
          const workspaceData = workspaceDoc.data();
          this.workspaceName = workspaceData.name;
          this.website = workspaceData.websiteLink;  // Update this line
          this.countryCode = workspaceData.countryCode;
          this.businessDescription = workspaceData.businessDescription;  // Update this line
          this.primaryEmail = workspaceData.primary_email;
          this.sendWeeklyReport = workspaceData.on_primary_email_send;
          this.phone_number = workspaceData.phone_number;
          this.timezone = workspaceData.timezone;
          this.sendWeeklyReportonphone = workspaceData.on_phone_number_send;
          this.editableWorkspace = { ...workspaceData, id: item.id, logo_original: workspaceData.logo_original || null };

          // Open the dialog
          this.nameDialog = true;
          this.isEditingWorkspace = true;
        } else {
          this.$snackbar.show("Workspace does not exist");
        }
      } catch (error) {
        console.error("Failed to fetch workspace data: ", error);
        this.$snackbar.show("Failed to fetch workspace data. Please try again.");
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();  // Ensure 'fileInput' is the correct ref name
    },
    handleLogoChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.brandLogo = file;
        // Optionally, read and preview the image immediately
        const reader = new FileReader();
        reader.onload = (e) => {
          this.editableWorkspace.logo_original = e.target.result;  // Update the preview immediately
        };
        reader.readAsDataURL(file);
      }
    },
    getVersion() {
      if (process.env.VUE_APP_ENV === "production") {
        return process.env.VUE_APP_VERSION;
      } else {
        return process.env.VUE_APP_ENV + "-" + process.env.VUE_APP_VERSION;
      }
    },
    logout() {
      signOut(auth);
    },
    async changeWorkspace(workspace) {
      // console.log("Switching to workspace:", workspace);
      // console.log("name",workspace.id,workspace.name);

      if (this.$route.name == "create-post") {
        this.$confirm.show({
          message: `All changes will be lost. Are you sure you want to switch workspace?`,
          onConfirm: async () => {
            localStorage.setItem("CURRENT_WORKSPACE_ID", workspace.id);
            localStorage.setItem("CURRENT_WORKSPACE", JSON.stringify(workspace));
            this.$store.commit("SET_CURRENT_WORKSPACE", workspace);
            /*
            localStorage.setItem("CURRENT_WORKSPACE_ID", workspace.id);
            this.$store.commit("SET_CURRENT_WORKSPACE_ID", workspace.id);

            // Check if the workspace is from `workspaces_granted`
            const newIDs = this.checkWorkspace(workspace.id);
            if (newIDs) {
              const new_workspace = {
                name: workspace.name,
                id: newIDs.wid,
              };
              localStorage.setItem("CURRENT_WORKSPACE_ID", newIDs.wid);
              localStorage.setItem("CURRENT_UID", newIDs.senderId);
              this.$store.commit("SET_CURRENT_WORKSPACE_ID", newIDs.wid);
              this.$store.commit("SET_CURRENT_UID", newIDs.senderId);
              this.$store.commit("SET_CURRENT_WORKSPACE", new_workspace);
            } else {
              localStorage.setItem("CURRENT_UID", this.currentOriginalUID);
              this.$store.commit("SET_CURRENT_UID", this.currentOriginalUID);
            }
            */
            // Trigger a full page reload after the router has navigated to "/dashboard"
            window.location.href = "/create";
          },
        });
      } else {
        localStorage.setItem("CURRENT_WORKSPACE_ID", workspace.id);
        localStorage.setItem("CURRENT_WORKSPACE", JSON.stringify(workspace));
        this.$store.commit("SET_CURRENT_WORKSPACE", workspace);
        /*
        localStorage.setItem("CURRENT_WORKSPACE_ID", workspace.id);
        this.$store.commit("SET_CURRENT_WORKSPACE_ID", workspace.id);

        // Check if the workspace is from `workspaces_granted`
        const newIDs = this.checkWorkspace(workspace.id);
        console.log("new id", newIDs);
        if (newIDs) {
          const new_workspace = {
            name: workspace.name,
            id: newIDs.wid,
          };
          localStorage.setItem("CURRENT_UID", newIDs.senderId);
          localStorage.setItem("CURRENT_WORKSPACE_ID", newIDs.wid);
          this.$store.commit("SET_CURRENT_WORKSPACE_ID", newIDs.wid);
          this.$store.commit("SET_CURRENT_UID", newIDs.senderId);
          this.$store.commit("SET_CURRENT_WORKSPACE", new_workspace);
        } else {
          localStorage.setItem("CURRENT_UID", this.currentOriginalUID);
          this.$store.commit("SET_CURRENT_UID", this.currentOriginalUID);
        }
          */
         this.$nextTick(() => {
          if (this.$route.name == "repost" || this.$route.name == "edit-post" || this.$route.name == "draft-post") {
            window.location.href = "/create";
          }
          else location.reload();
        });
      }
    },

    async editWorkspace() {
      // console.log("Adding workspace with", this.website, this.businessDescription);
      this.$loader.show("Updating the workspace");
      this.menu = false;
      try {
        let workspaceRef = doc(db, "users", this.uid, "fly_workspaces", this.editableWorkspace.id.split('__')[0]);
        let logoURL = this.editableWorkspace.logo_original;

        // Ensure updateData is declared before being used
        const updateData = {};  // Initialize updateData as an empty object

        // Only update the logo URL if a new brand logo has been provided
        if (this.brandLogo && typeof this.brandLogo !== 'string') {
          const storageRef = getStorage();
          const logoRef = ref(storageRef, `logos/${this.currentUID}/${this.brandLogo.name}`);
          const snapshot = await uploadBytes(logoRef, this.brandLogo);
          logoURL = await getDownloadURL(snapshot.ref);
          updateData.logo_original = logoURL;  // Add logo URL to updateData
        }

        if (this.workspaceName) {
          updateData.name = this.workspaceName;
          updateData.brandName = this.workspaceName; // Synchronize brandName with workspaceName
        }
        if (this.website) updateData.websiteLink = this.website;  // Update website if changed
        if (this.businessDescription) updateData.businessDescription = this.businessDescription;  // Update description if changed
        if (this.primaryEmail) updateData.primary_email = this.primaryEmail;
        if (typeof this.sendWeeklyReport === 'boolean') updateData.on_primary_email_send = this.sendWeeklyReport;
        if (this.phone_number) updateData.phone_number = this.phone_number;
        if (typeof this.sendWeeklyReportonphone === 'boolean') updateData.on_phone_number_send = this.sendWeeklyReportonphone;
        if (this.countryCode) updateData.countryCode = this.countryCode;
        if (this.timezone) updateData.timezone = this.timezone;  // Add this line to update timezone

        // Perform the update if there are fields to update
        if (Object.keys(updateData).length > 0) {
          await updateDoc(workspaceRef, updateData);
          this.$snackbar.show("Workspace updated successfully");
        }

        // Clear input fields and close the dialog after successful update
        this.nameDialog = false; // Close the dialog
        this.clearEditFields();
        this.listenUsers(); // Refresh list to reflect changes
      } catch (error) {
        console.error("Error updating workspace: ", error);
        this.$snackbar.show("Failed to update workspace. Please try again.");
      }
      this.$loader.hide();
    },



    // Method to clear fields
    clearEditFields() {
      this.workspaceName = "";
      this.website = "";
      this.businessDescription = "";
      this.primaryEmail = "";
      this.sendWeeklyReport = false;
      this.phone_number = "";
      this.sendWeeklyReportonphone = false;
      this.brandLogo = null; // Clear brand logo if necessary
    },
    async populateWorkspaceFields() {
  console.log("called populateWorkspaceFields");
  try {
    // Assuming vm.uid is the user's UID
    const userRef = doc(db, "users", auth.currentUser.uid);
    const userDoc = await getDoc(userRef);
    console.log("user", userDoc);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      console.log("User data:", userData);

      // Populate user-related fields (primaryEmail and phone number)
      this.primaryEmail = userData.email || "";
      this.phone_number = userData.phone_number || "";

      // Retrieve the default workspace data
      const workspaceRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "fly_workspaces",
        this.WID || "default"
      );
      const workspaceDoc = await getDoc(workspaceRef);

      if (workspaceDoc.exists()) {
        const workspaceData = workspaceDoc.data();

        // Auto-populate the country code, then convert it to the full country name
        const countryCode = workspaceData.countryCode || userData.country_code || "IN";  // Default to "IN"
        this.countryCode = this.getCountryObject(countryCode);  // Convert to full country name

        // Auto-populate the timezone with label and value
        if (workspaceData.timezone) {
          const tzName = workspaceData.timezone;
          const tzOffset = moment.tz(tzName).utcOffset();  // Get timezone offset in minutes
          const offsetHours = Math.floor(Math.abs(tzOffset) / 60);
          const offsetMinutes = Math.abs(tzOffset) % 60;
          const offsetSign = tzOffset >= 0 ? '+' : '-';
          const formattedOffset = `${offsetSign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;

          this.timezone = {
            label: `(UTC ${formattedOffset}) ${tzName}`,
            value: tzName,
          };
        } else {
          this.timezone = null;  // Clear the timezone if none is stored
        }

        // Use user data if not defined in the workspace for primaryEmail and phone_number
        this.primaryEmail = this.primaryEmail || workspaceData.primary_email || "";
        this.phone_number = this.phone_number || workspaceData.phone_number || "";
      } else {
        console.log("Default workspace does not exist.");
      }
    } else {
      console.log("No user data found.");
    }
  } catch (error) {
    console.error("Error fetching workspace/user data: ", error);
    this.$snackbar.show(
      "Failed to fetch workspace/user data. Please try again."
    );
  }
}


,
async addWorkspace() {
  this.$loader.show("Creating the workspace");
  try {
    // Fetch user data and limit
    let userData = (await getDoc(doc(colUsers, this.currentUID))).data();
    const monthly_limit = userData.addon_monthly_credits?.brands || 0;
    let plan_data = (await getDoc(doc(flyplans, userData?.fly_plan))).data();
    let limit = (plan_data?.limits?.brands || 0) + monthly_limit;

    // Fetch workspaces and count total
    let workspaceRef = collection(db, "users", this.currentUID, "fly_workspaces");
    const workspaceSnapshot = await getDocs(workspaceRef);
    let totalWorkspaces = workspaceSnapshot.docs.length;

    // Check workspace limit
    if (!limit || (limit && totalWorkspaces < limit)) {
      // Check if a workspace with the same name exists
      const querySnapshot = await getDocs(workspaceRef);
      const workspaceExists = querySnapshot.docs.some(
        (doc) => doc.data().name === this.brandName
      );
      
      if (workspaceExists) {
        this.errorMessage = "Workspace already exists with this name";
      } else {
        // Upload brand logo and get the URL
        let logoURL = '';
        if (this.brandLogo) {
          const storageRef = getStorage();
          const logoRef = ref(storageRef, `logos/${this.currentUID}/${this.brandLogo.name}`);
          const snapshot = await uploadBytes(logoRef, this.brandLogo);
          logoURL = await getDownloadURL(snapshot.ref);
        }

        // Prepare data for creating a new workspace
        const workspaceData = {
          name: this.brandName,
          brandName: this.brandName,
          websiteLink: this.website || "", // Website link is optional, so provide an empty string as default
          businessDescription: this.businessDescription || "", // Optional field with default
          logo_original: logoURL,
          primary_email: this.primaryEmail || "", // Optional field with default
          on_primary_email_send: this.sendWeeklyReport,
          phone_number: this.phone_number || "", // Optional field with default
          on_phone_number_send: this.sendWeeklyReportonphone
        };

        // Conditionally add timezone and countryCode if they exist
        if (this.timezone && typeof this.timezone === 'object') {
          workspaceData.timezone = this.timezone.value;
        } else if (this.timezone) {
          workspaceData.timezone = this.timezone; // If it's already a string
        }

        if (this.countryCode && typeof this.countryCode === 'object') {
          workspaceData.countryCode = this.countryCode.value;
        } else if (this.countryCode) {
          workspaceData.countryCode = this.countryCode; // If it's already a string
        }

        // Create the workspace in Firestore
        const newWorkspaceRef = await addDoc(workspaceRef, workspaceData);

        const newWorkspaceId = newWorkspaceRef.id;
        let payload = {
          uid: this.currentUID,
          wid: newWorkspaceId,
          url: this.website,
          description: this.businessDescription,
        };

        // Optionally generate AI content if the business description is provided
        if (this.businessDescription) {
          const generateAiContent = httpsCallable(functions, "generateAiContentFunction");
          await generateAiContent(payload);
        }

        // Show success and reset fields
        this.$snackbar.show("Workspace created successfully");
        this.listenUsers();
        this.dialog = false;
        this.resetFormFields();
      }
    } else {
      this.$alert.show("Workspace limit reached. Please try again.");
    }
  } catch (error) {
    console.error("Error creating workspace:", error);
    this.$alert.show("Failed to create workspace. Please try again.");
  } finally {
    this.$loader.hide();
  }
},
// Reset form fields after workspace creation
resetFormFields() {
  this.brandName = "";
  this.website = "";
  this.businessDescription = "";
  this.countryCode = ""; // Reset to empty or default
  this.brandLogo = null;
  this.primaryEmail = "";
  this.sendWeeklyReport = false;
  this.phone_number = "";
  this.sendWeeklyReportonphone = false;
} ,
    async checkWorkspaceLimit() {
  try {
    const userData = (await getDoc(doc(colUsers, this.currentUID))).data();
    const monthly_limit = userData.addon_monthly_credits?.brands || 0;
    let plan_data = (await getDoc(doc(flyplans, userData?.fly_plan))).data();
    let limit = plan_data?.limits?.brands || 0;
    limit += monthly_limit;

    const workspaceRef = collection(
      db,
      "users",
      this.currentUID,
      "fly_workspaces"
    );
    const workspaceSnapshot = await getDocs(workspaceRef);
    const totalWorkspaces = workspaceSnapshot.docs.length;
    if (totalWorkspaces < limit) {
      await this.populateWorkspaceFields(); // Ensure this is called before opening the dialog
      this.dialog = true; // Open the dialog
    } else {
      this.limitDialog = true;
    }
  } catch (error) {
    console.error("Error checking workspace limit: ", error);
    this.$alert.show(
      "Failed to check workspace limit. Please try again."
    );
  }
}
,
    convertId(id) {
      // Split the string by '/'
      const parts = id.split('/');
      // Get the last part (default) and the second part (user ID)
      const lastPart = parts[parts.length - 1];
      const userId = parts[1];
      // Combine them with '__'
      const result = `${lastPart}__${userId}`;
      return result;
    },
    async listenUsers() {
      const vm = this;
      try {
          // Fetch user document
          const userDoc = (await getDoc(doc(colUsers, this.uid))).data();
          const userFlyPlan = await userDoc?.fly_plan;
          const allowedPlans = process.env.VUE_APP_WK_PLANS.split(",").map(
              (plan) => plan.trim()
          );
          this.shouldRenderMenu = allowedPlans.includes(userFlyPlan);

          // Fetch and listen for changes in user workspaces
          onSnapshot(getWorkspaceRef(this.uid), (snapshot) => {
              let userWorkspaces = mapDocs(snapshot.docs);

              // Initialize workspaces array
              let workspaces = [...userWorkspaces].filter(workspace => !workspace.storeWorkspace).map((i) => {
                  return {
                      id: `${i.id}__${this.uid}`,
                      name: i?.name,
                      storeWorkspace:i?.storeWorkspace||null,
                      businessDescription: i?.businessDescription||'',
                      phone_number: i?.phone_number||'',
                      primary_email: i?.primary_email||'',
                      websiteLink: i?.websiteLink||'', 
                      wid: i.id,
                      uid: this.uid,
                      type: "user",
                      logo_original: i?.logo_original,
                  };
              });

              // Set current workspace if not already set
              let localWorkspace = localStorage.getItem("CURRENT_WORKSPACE");
              if (!localWorkspace || localWorkspace === "null" || localWorkspace === "") {
                  let settingWorkspace = workspaces.find((w) => w.wid == "default");
                  this.$store.commit("SET_CURRENT_WORKSPACE", settingWorkspace);
                  localStorage.setItem("CURRENT_WORKSPACE", JSON.stringify(settingWorkspace));
                  localStorage.setItem("CURRENT_WORKSPACE_ID", settingWorkspace.id);
              }

              // Listen for changes in granted workspaces using onSnapshot
                onSnapshot(getSharedWorkspaceRef(this.uid), async (snapshot) => {
                  let grantedWorkspaces = mapDocs(snapshot.docs);
                  snapshot.docChanges()?.forEach((change) => {
                    if (change.type === "modified") {
                      console.log("Document updated: ", change.doc.data());
                      const currWS = JSON.parse(localStorage.getItem('CURRENT_WORKSPACE'));
                      currWS.role = change.doc.data()?.role
                      localStorage.setItem("CURRENT_WORKSPACE",JSON.stringify(currWS));
                      this.$nextTick(() => {
                        location.reload();
                      });
                    }
                  });

                  // Fetch the shared workspaces data
                  let requests = grantedWorkspaces.map((i) =>
                      getDoc(getWorkspaceDocRef(i.owner, i.wid))
                  );
                  let sharedWorkspaces = (await Promise.allSettled(requests)).map(
                      (i) => i.value
                  );
                  sharedWorkspaces = sharedWorkspaces.filter((i) => i.data() != null);

                  // Merge user and shared workspaces
                  let updatedWorkspaces = [...workspaces];

                  for (let workspace of grantedWorkspaces) {
                      let item = sharedWorkspaces.find((i) => this.convertId(i.ref.path) == workspace.id);
                      if (item?.data()) {
                          updatedWorkspaces.push({
                              id: `${workspace.wid}__${workspace.owner}`,
                              name: item.data().name,
                              businessDescription: item.data()?.businessDescription||'',
                              phone_number: item.data()?.phone_number||'',
                              primary_email: item.data()?.primary_email||'',
                              websiteLink: item.data()?.websiteLink||'', 
                              wid: workspace.wid,
                              uid: workspace.owner,
                              role: workspace.role,
                              type: "shared",
                              logo_original: item.data().logo_original,
                          });
                      }
                  }

                  // Sort workspaces by name
                  const localWorkspaceId = (JSON.parse(localWorkspace))?.id;
                  const checkDeletedShared = updatedWorkspaces.find((w) => w.id == localWorkspaceId);
                  if (!checkDeletedShared && localWorkspaceId) {
                      const UserDefaultWS = updatedWorkspaces.find((w) => w.id == `default__${this.uid}`);
                      this.changeWorkspace(UserDefaultWS);
                  }
                  if (this.$route?.params?.invite_id) {
                      const invitedWorkspace = updatedWorkspaces.find(w => w.uid === this.$route.params.invite_id);
                      if (invitedWorkspace) {
                          this.changeWorkspace(invitedWorkspace);
                      }
                      if (this.$route.params.invite_id !== null) {
                          const updatedParams = { ...this.$route.params, invite_id: null };
                          this.$router.replace({ name: this.$route.name, params: updatedParams });
                      }
                  }
                  updatedWorkspaces = updatedWorkspaces.sort((a, b) => a.name?.localeCompare(b.name));

                  // Update workspaces
                  this.workspaces = updatedWorkspaces;
                  vm.isLoading = false;
              });
          });
      } catch (error) {
          this.handleError(error);
      }
      /*
      let workspaces = {};
      let index = 0;
      const localStorageWID = localStorage.getItem("CURRENT_WORKSPACE_ID");
      let currentWID = localStorageWID || "default";
      console.log(currentWID, "this is current WID");
      const userDoc = (await getDoc(doc(colUsers, this.uid))).data();
      const userFlyPlan = await userDoc.fly_plan;
      const allowedPlans = process.env.VUE_APP_WK_PLANS.split(",").map((plan) =>
        plan.trim()
      );
      this.shouldRenderMenu = allowedPlans.includes(userFlyPlan);
    
      let workspaceRef = collection(db, "users", this.uid, "fly_workspaces");
      const subcollectionsSnapshot = await getDocs(workspaceRef);
      subcollectionsSnapshot.forEach((subcollectionDoc) => {
        const workspaceId = subcollectionDoc.id;
        const workspaceData = subcollectionDoc.data();
    
        workspaces[index] = { id: workspaceId, name: workspaceData.name };
        index++;
      });
    
      // Fetch workspaces from the workspaces_granted collection
      let grantedWorkspaceRef = collection(
        db,
        "users",
        this.uid,
        "workspaces_granted"
      );
      const grantedWorkspaceSnapshot = await getDocs(grantedWorkspaceRef);
      if (!grantedWorkspaceSnapshot.empty) {
        for (const grantedDoc of grantedWorkspaceSnapshot.docs) {
          const grantedWorkId = grantedDoc.id;
          const { wid, senderId } = this.checkWorkspace(grantedWorkId);
          const grantedWorkspaceData = (
            await getDoc(doc(colUsers, senderId, "fly_workspaces", wid))
          ).data();
          workspaces[index] = {
            id: grantedWorkId,
            name: grantedWorkspaceData.name,
          };
          index++;
        }
      }
    
      const curr_uid = localStorage.getItem("CURRENT_UID");
      let notUserwid;
      if (this.currentOriginalUID != curr_uid) {
        notUserwid = currentWID;
        currentWID = `${currentWID}__${curr_uid}`;
      }
      // console.log(currentWID, "this is printed");
      // console.log(workspaces, "this workspaces is printed");
    
      this.$store.commit("SET_ALL_WORKSPACES", workspaces);
      vm.workspaces = workspaces;
    
      let workspace = Object.values(workspaces).find(
        (workspace) => workspace.id === currentWID
      );
      vm.isLoading = false;
      console.log(workspace, "this outside founded workspace");
      if (workspace) {
        console.log(workspace, "this inside founded workspace");
        localStorage.setItem("CURRENT_WORKSPACE_NAME", workspace.name);
        localStorage.setItem("CURRENT_WORKSPACE_ID", notUserwid || currentWID);
        this.$store.commit(
          "SET_CURRENT_WORKSPACE_ID",
          notUserwid || currentWID
        );
        this.$store.commit("SET_CURRENT_WORKSPACE", workspace);
      }
      vm.isLoading = false;
      */
    },
  },
  mounted() {

    onAuthStateChanged(auth, async (user) => {
      if (this.subscribe) this.subscribe();
      if (user) {
        await this.listenUsers();
      }
    });
    this.initCountries();
  },
};
</script>

<style scoped>
.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.workspace-list {
  max-height: 500px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 9px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(230, 229, 229, 0.2);
  border-radius: 6px;
}

.selected-workspace {
  background-color: rgba(51, 58, 72, var(--tw-bg-opacity)) !important;
  /* Adjust the color as needed */
  border-radius: 20rem;
  /* Equivalent to 'rounded-md' in Tailwind CSS */
}

.v-list-item--link:before {
  display: none !important;
}

.workspace-list {
  max-height: 500px;
  overflow-y: scroll;
  background-color: #1C2434 !important;
  /* Force this background color */
}

.primary-email-input .v-text-field__slot {
  height: 20px;
  /* Adjust height as necessary */
}

.v-text-field input {
  padding: 0px;
}
</style>